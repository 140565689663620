import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Menu from './menu/Menu';
import { Link } from 'react-router-dom';

import './sidebar.scss';
import store from 'client/store';

@observer
export default class Sidebar extends React.Component {
	constructor(props) {
		super(props);
	}

	onOverlayClick = () => {
		store.ui.menu = false;
	};

	render() {
		return (
			<>
				<div className={'mobile-overlay' + (store.ui.menu ? ' active' : '')} onClick={this.onOverlayClick} />
				<div className={'sidebar' + (store.ui.menu ? ' active' : '')}>
					<div className='logo'>
						<Link to='/'>
							<img src='/api/logo' />
						</Link>
					</div>
					<Menu />
				</div>
			</>
		);
	}
}
