import { action, observable } from 'mobx';
import t from 'i18n';
import { v4 as uuid } from 'uuid';

export default class UI {
	@observable title = '';
	@observable menu = false;
	@observable activeSubMenu = null;
	@observable kanban = false;
	@observable notifications = [];

	constructor(store) {
		this.store = store;
	}

	setNotification = (notification) => {
		if (notification) {
			this.notifications.unshift({ ...notification, id: uuid() });
		}
	};

	removeNotification = (id) => {
		const index = this.notifications.findIndex((n) => n.id === id);
		index !== -1 && this.notifications.splice(index, 1);
	};
}
