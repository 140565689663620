import store from 'client/store';
import { observable } from 'mobx';
import { KG_TO_LBS } from 'client/constants';
import { endOfDay, format, startOfDay } from 'date-fns';
import i18next from 'i18next';
import { CONFIGURATION_INCLUDE } from 'client/pages/planes/constants';
import { DAT_ACCESSIBLE_MANUFACTURER, XLSX_ACCESSIBLE_MANUFACTURER } from '../constants';
import { stringToBoolean } from 'client/tools';
export class tableReportStore {
	@observable flightPhase = {};
	@observable BUTTONS = [];
	@observable items = {};
	@observable selectedColumns = [];
	@observable COLUMNS = [{ property: 'flightTime', width: 150, label: 'flightTime', isSelected: true, isShared: true }];
	@observable mergedColumns = [];
	@observable aircraft = null;
	@observable isEdit = false;
	@observable records = [];
	@observable isLoading = false;
	@observable startDate = null;
	@observable endDate = null;
	@observable activeEngines = [];
	@observable key = 0;
	@observable isBombardie = false;
	@observable isInit = false;

	perPage = 10;
	@observable page = 1;
	enablePagination = true;
	totalCount = 0;

	selectedFlightPhase = null;
	localStorage = store.local.aircraft.tableReport;

	// Селект конфигурации устанавливаемых двигателей
	enginesConfigurationItems = [];
	@observable configuration = null;

	constructor(aircraftId) {
		this.id = aircraftId;
		this.fetchStart();
	}

	fetchStart = async () => {
		let aircraft = new store.model.Aircraft();

		if (this.id) {
			aircraft = await store.model.Aircraft.findById(this.id, {
				include: ['manufacturer', 'type', 'family', 'operator', 'holder', 'engines'],
			});
		}
		this.aircraft = aircraft;

		this.BUTTONS = await this.fetchButtons();
		this.flightPhase = this.BUTTONS[0];
		this.enablePagination = stringToBoolean(store.mergedConfig.enableTableReportPagination || 'true');
		await this.getEnginesConfiguration();
		await this.init();
		await this.fetchColumns();
		this.onMergeColumns();
		this.isInit = true;
	};

	getEnginesConfiguration = async () => {
		const configurations = this.groupByProperty(
			await this.aircraft.configurations.find({ include: CONFIGURATION_INCLUDE, order: 'enginePosition asc' }),
			'date'
		);
		const plateEngines = await store.model.Engine.find({ where: { aircraftId: this.id } });

		const currentPlaneConf = plateEngines
			.sort((a, b) => a.enginePosition - b.enginePosition)
			.map((r) => r.id)
			.join('/');

		this.enginesConfigurationItems = Object.values(configurations).map((r, index) => {
			const conf = r
				.sort((a, b) => a.enginePosition - b.enginePosition)
				.map((r) => r.engineId)
				.join('/');
			return {
				id: index,
				values: r,
				label: r.map((_r) => _r.engine.serialNumber).join('/'),
				configurationName: conf,
				current: conf === currentPlaneConf,
			};
		});

		if (this.enginesConfigurationItems.length)
			this.configuration = this.enginesConfigurationItems.find((r) => !!r.current) || this.enginesConfigurationItems[0];
	};

	onChangeConfiguration = async (item) => {
		this.configuration = item;
		this.page = 1;
		await this.init();
		this.onMergeColumns();
	};

	groupByProperty = (data, property) => {
		const groups = data.reduce((acc, current, i) => {
			if (current[property]) {
				const key = current[property];

				if (!acc[key]) {
					acc[key] = [];
				}

				acc[key].push(current);
			}

			return acc;
		}, {});

		return groups;
	};

	init = async () => {
		//todo fields = selected columns
		this.isLoading = true;
		await await store.fetchDbServerConfig();
		this.selectedFlightPhase = await this.fetchFlightPhase();

		this.startDate = this.localStorage.startDate && new Date(this.localStorage.startDate);
		this.endDate = this.localStorage.endDate && new Date(this.localStorage.endDate);

		const where = {
			aircraftId: this.id,
			phaseId: this.selectedFlightPhase.id,
			and: [],
		};

		if (this.startDate) {
			where.and.push({
				flightTime: {
					gte: startOfDay(this.startDate),
				},
			});
		}

		if (this.endDate) {
			where.and.push({
				flightTime: {
					lte: endOfDay(this.endDate),
				},
			});
		}

		this.query = { where, order: 'flightTime asc' };
		if (this.enablePagination) {
			this.query.limit = this.perPage;
			this.query.skip = (this.page - 1) * this.perPage;
		}

		if (this.configuration) where.and.push({ enginesConfiguration: this.configuration.configurationName });

		try {
			this.records = await store.model.ViewInput.find(this.query);
			this.totalCount = this.records.totalCount;
			const isConvert = stringToBoolean(store.mergedConfig?.isConverKgToLbs || 'false');
			const ZT49 = (await store.model.Parameter.find({ where: { code: 'ZT49' } }))?.[0] || {};
			const ZT49_VALUE =
				(
					await store.model.ParameterLibrary.find({
						where: { parameterId: ZT49?.id, phaseId: this.flightPhase.id },
						fields: ['parameterId', 'maxLimit', 'phaseId'],
					})
				)?.[0] || {};

			this.records = this.records.map((record) => {
				if (record.ZWF36_1) {
					record.ZWF36_1 = (Number(record.ZWF36_1) / (isConvert ? KG_TO_LBS : 1)).toFixed(3);
				}
				if (record.ZWF36_2) {
					record.ZWF36_2 = (Number(record.ZWF36_2) / (isConvert ? KG_TO_LBS : 1)).toFixed(3);
				}
				if (this.isBombardie) {
					record.flightTime = record.flightTime.replace('Z', '');
				}
				const maxLimitZT49 = ZT49_VALUE.maxLimit;
				const ZT49_1 = record.ZT49_1;
				const ZT49_2 = record.ZT49_2;
				record.ZT49_M_1 = ZT49_1 ? ((maxLimitZT49 - ZT49_1) / 3.5).toFixed(3) : null;
				record.ZT49_M_2 = ZT49_2 ? ((maxLimitZT49 - ZT49_2) / 3.5).toFixed(3) : null;
				return record;
			});
		} catch (e) {
			console.error('Fetching orders error:', e);
		}

		this.isLoading = false;
	};

	onPageChange = (value) => {
		this.page = value;
		this.init();
	};

	fetchButtons = async () => {
		const manufacturer = this.aircraft?.manufacturer?.name || '';
		this.isBombardie = DAT_ACCESSIBLE_MANUFACTURER.some(m => m.includes(manufacturer?.toLowerCase()));
		this.isGSS = XLSX_ACCESSIBLE_MANUFACTURER.some(m => m.includes(manufacturer?.toLowerCase()));
		
		const paramsFilter = (parameters) => {
			return parameters
				.filter((param) => {
					if (this.isBombardie) {
						return DAT_ACCESSIBLE_MANUFACTURER.some(m => m.includes(param.manufacturer?.name?.toLowerCase()));
					} else if (this.isGSS) {
						return XLSX_ACCESSIBLE_MANUFACTURER.some(m => m.includes(param.manufacturer?.name?.toLowerCase()));
					} else {
						return !DAT_ACCESSIBLE_MANUFACTURER.some(m => m.includes(param.manufacturer?.name?.toLowerCase())) &&  !XLSX_ACCESSIBLE_MANUFACTURER.some(m => m.includes(param.manufacturer?.name?.toLowerCase()));
					}
				})
				.map((param) => param.code);
		};

		const TAKEOFF_AIRCRAFT_PARAMETER_NAMES = (
			await store.model.Parameter.find({ where: { modelName: 'AircraftInput' }, include: ['phases', 'manufacturer'] })
		).filter((param) =>
			param
				.phases()
				?.map((phase) => phase.name)
				?.includes('TAKEOFF')
		);
		const TAKEOFF_ENGINE_PARAMETER_NAMES = (
			await store.model.Parameter.find({ where: { modelName: 'EngineInput' }, include: ['phases', 'manufacturer'] })
		).filter((param) =>
			param
				.phases()
				?.map((phase) => phase.name)
				?.includes('TAKEOFF')
		);
		const CRUISE_AIRCRAFT_PARAMETER_NAMES = (
			await store.model.Parameter.find({ where: { modelName: 'AircraftInput' }, include: ['phases', 'manufacturer'] })
		).filter((param) =>
			param
				.phases()
				?.map((phase) => phase.name)
				?.includes('CRUISE')
		);
		const CRUISE_ENGINE_PARAMETER_NAMES = (
			await store.model.Parameter.find({ where: { modelName: 'EngineInput' }, include: ['phases', 'manufacturer'] })
		).filter((param) =>
			param
				.phases()
				?.map((phase) => phase.name)
				?.includes('CRUISE')
		);

		const phase = this.isBombardie ? ['CRUISE'] : ['TAKEOFF', 'CRUISE'];
		return phase.map((flightPhaseName) => {
			let fields = {};
			if (flightPhaseName === 'TAKEOFF') {
				fields = {
					phaseName: 'TAKEOFF',
					aircraftParams: [...paramsFilter(TAKEOFF_AIRCRAFT_PARAMETER_NAMES)],
					engineParams: [...paramsFilter(TAKEOFF_ENGINE_PARAMETER_NAMES)],
				};
			} else if (flightPhaseName === 'CRUISE') {
				fields = {
					phaseName: 'CRUISE',
					aircraftParams: [...paramsFilter(CRUISE_AIRCRAFT_PARAMETER_NAMES)],
					engineParams: [...paramsFilter(CRUISE_ENGINE_PARAMETER_NAMES)],
				};
			}
			const ZT49Index = fields.engineParams.findIndex((r) => r === 'ZT49');
			if (ZT49Index !== -1) {
				fields.engineParams.splice(ZT49Index + 1, 0, 'ZT49_M');
			}
			return fields;
		});
	};

	fetchColumns = async () => {
		const manufacturer = this.aircraft?.manufacturer?.name || '';
		this.isBombardie = DAT_ACCESSIBLE_MANUFACTURER.some(m => m.includes(manufacturer?.toLowerCase()));
		this.isGSS = XLSX_ACCESSIBLE_MANUFACTURER.some(m => m.includes(manufacturer?.toLowerCase()));

		let parameters = (await store.model.Parameter.find({ include: ['phases'] })).filter(
			(param) => param.phases()?.length === 1 || param.phases()?.length === 2
		);

		parameters = parameters.filter((param) => {
			const isParamBombardie = DAT_ACCESSIBLE_MANUFACTURER.some(m => m.includes(param.manufacturer?.name?.toLowerCase()));
			const isParamGSS = XLSX_ACCESSIBLE_MANUFACTURER.some(m => m.includes(param.manufacturer?.name?.toLowerCase()));

			if (this.isBombardie) {
				return isParamBombardie;
			} else if (this.isGSS) {
				return isParamGSS;
			} else {
				return !isParamBombardie && !isParamGSS;
			}
		});

		const _COLUMNS = parameters
			.map((param) => {
				let phaseName = '';
				if (param.phases()?.length === 1) {
					phaseName = param
						.phases()
						?.map((phase) => phase.name)
						?.includes('CRUISE')
						? 'CRUISE'
						: 'TAKEOFF';
				}

				if (param.modelName === 'AircraftInput') {
					return [
						{
							property: param.code,
							label: param.code,
							isSelected: true,
							modelName: param.modelName,
							phaseName,
							isShared:
								param
									.phases()
									?.map((phase) => phase.name)
									?.includes('CRUISE') &&
								param
									.phases()
									?.map((phase) => phase.name)
									?.includes('TAKEOFF'),
						},
					];
				}
				return [
					{
						property: param.code + '_1',
						label: param.code,
						isSelected: true,
						modelName: param.modelName,
						phaseName,
						isShared:
							param
								.phases()
								?.map((phase) => phase.name)
								?.includes('CRUISE') &&
							param
								.phases()
								?.map((phase) => phase.name)
								?.includes('TAKEOFF'),
					},
					{
						property: param.code + '_2',
						label: param.code,
						isSelected: true,
						modelName: param.modelName,
						phaseName,
						isShared:
							param
								.phases()
								?.map((phase) => phase.name)
								?.includes('CRUISE') &&
							param
								.phases()
								?.map((phase) => phase.name)
								?.includes('TAKEOFF'),
					},
				];
			})
			.flat();

		const zt49_1Index = _COLUMNS.findIndex((r) => r.property === 'ZT49_1');
		const zt49_2Index = _COLUMNS.findIndex((r) => r.property === 'ZT49_2');
		if (zt49_1Index && zt49_2Index) {
			_COLUMNS.splice(zt49_2Index + 1, 0, { ..._COLUMNS[zt49_2Index], property: 'ZT49_M_2', label: 'ZT49_M' });
			_COLUMNS.splice(zt49_2Index + 1, 0, { ..._COLUMNS[zt49_1Index], property: 'ZT49_M_1', label: 'ZT49_M' });
		}

		if (DAT_ACCESSIBLE_MANUFACTURER.some(str => str.includes(manufacturer?.toLowerCase()))) {
			this.COLUMNS = [{ property: 'flightTime', width: 150, label: 'flightTime', isSelected: true, isShared: true }].concat(_COLUMNS);
		} else {
			this.COLUMNS = this.COLUMNS.concat(_COLUMNS);
		}

		this.selectedColumns = this.COLUMNS;
	};

	onMergeColumns = () => {
		const mergedArray = [];
		const tempObject = {};
		const columnsLocalStorage = {};

		const newColumns = this.COLUMNS.filter((column) => {
			return column.phaseName === this.flightPhase.phaseName;
		});

		const sharedColumns = this.selectedColumns.filter((col) => {
			return col.isShared;
		});

		this.selectedColumns = sharedColumns.concat(newColumns);

		if (this.localStorage.selectedColumns[this.flightPhase.phaseName]?.length) {
			this.localStorage.selectedColumns[this.flightPhase.phaseName].forEach((column) => {
				columnsLocalStorage[column.property] = column.isSelected;
			});
			columnsLocalStorage['flightTime'] = true;
		}

		for (const obj of this.selectedColumns) {
			if (!tempObject[obj.label]) {
				tempObject[obj.label] = { ...obj, label: obj.label, isSelected: columnsLocalStorage[obj.property] ?? obj.isSelected, properties: [] };
			}
			tempObject[obj.label].properties.push(obj.property);
		}

		for (const label in tempObject) {
			mergedArray.push(tempObject[label]);
		}
		this.mergedColumns = mergedArray;
	};

	onChangeEngine = (engine) => () => {
		const index = this.activeEngines.findIndex((value) => value === engine);
		index !== -1 ? this.activeEngines.splice(index, 1) : this.activeEngines.push(engine);
	};

	styleEngineBtn = (engine) => (this.activeEngines.find((item) => item === engine) ? 'engine-button-wrapper active-engine-btn' : 'engine-button-wrapper');

	onDateChange = (prop) => async (object) => {
		const { value, e } = object;
		this[prop] = value;
		this.localStorage[prop] = value;
		store.local.save();
		this.page = 1;
		await this.init();
		this.onMergeColumns();
	};

	onAircraftChange = async (value) => {
		this.aircraft = value;
		this.id = this.aircraft.id;
		this.COLUMNS = [];

		await this.fetchStart();
	};

	fetchFlightPhase = async () => {
		const selectedPhase = (
			await store.model.Phase.find({
				where: {
					name: this.flightPhase.phaseName,
				},
			})
		)[0];

		return selectedPhase;
	};

	onPhaseChange = async (value) => {
		this.flightPhase = value;
		this.selectedFlightPhase = await this.fetchFlightPhase();
		this.page = 1;
		await this.init();

		this.localStorage.flightPhase = this.flightPhase;
		store.local.save();

		const newColumns = this.COLUMNS.filter((column) => {
			return column.phaseName === this.flightPhase.phaseName;
		});

		this.selectedColumns = this.selectedColumns.filter((col) => {
			return col.isShared;
		});

		this.selectedColumns = this.selectedColumns.concat(newColumns);
		this.onMergeColumns();
	};

	printPdf = () => {
		window.print();
		// при скачивание отчета, создается запись в журнале (о просмотре отчета)
		const instance = {
			serialNumber: this.aircraft.serialNumber,
			phase: this.selectedFlightPhase.name,
		};
		this.startDate && (instance.dateStart = format(new Date(this.startDate), 'yyyy-MM-dd'));
		this.endDate && (instance.dateEnd = format(new Date(this.endDate), 'yyyy-MM-dd'));
		const newAuditItem = new store.model.Audit({
			action: 'tableReportPdfDownload',
			model: 'Audit',
			instance,
		});
		newAuditItem.save();
	};

	downloadReport = async () => {
		const engines = await store.model.Engine.find({
			where: {
				aircraftId: this.aircraft.id,
			},
			include: ['type'],
		});

		const dateCut = (date) => {
			return date ? format(new Date(date), 'yyyy.MM.dd') : 'none';
		};

		let reportHeader = [
			['REPORT ID:', this.selectedFlightPhase.name === 'TAKEOFF' ? 'TKSUMM' : 'CRSUMM', '', '', '', '', `REPORT DATE: ${format(new Date(), 'yyyy-MM-dd')}`],
			['GE ENGINE CONDITION MONITORING PROGRAM'],
			[
				`${this.selectedFlightPhase.name} SUMMARY REPORT FROM ${format(new Date(this.startDate), 'yyyy-MM-dd')} TO ${format(
					new Date(this.endDate),
					'yyyy-MM-dd'
				)}`,
			],
			[
				`${this.selectedFlightPhase.name} SUMMARY REPORT
				${this.startDate && this.endDate
					? `FROM ${dateCut(this.startDate)} TO ${dateCut(this.endDate)}`
					: this.startDate
						? `FROM ${dateCut(this.startDate)}`
						: this.endDate
							? `TO ${dateCut(this.endDate)}`
							: 'FOR ALL TIME'
				}`,
			][('AIRCRAFT ID', 'TYPE', '', 'ENG POS', 'SERIAL NUM', '', 'ENG TYPE', '', 'INST DATE')],
		];
		!this.activeEngines.find((eng) => eng === 2) &&
			reportHeader.push([
				this.aircraft.serialNumber,
				this.aircraft.type.name,
				'',
				engines[0]?.enginePosition || 'none',
				engines[0]?.serialNumber || 'none',
				'',
				engines[0]?.type?.name || 'none',
				'',
				dateCut(engines[0]?.installationDate) || 'none',
			]);
		!this.activeEngines.find((eng) => eng === 1) &&
			reportHeader.push([
				'',
				'',
				'',
				engines[1]?.enginePosition || 'none',
				engines[1]?.serialNumber || 'none',
				'',
				engines[1]?.type?.name || 'none',
				'',
				dateCut(engines[1]?.installationDate) || 'none',
			]);

		const newArray = [];
		this.mergedColumns.forEach((obj) => {
			obj.properties.forEach((property) => {
				newArray.push({ ...obj, property: property });
			});
		});

		this.selectedColumns = newArray;

		const cols = this.selectedColumns
			.filter((col) => col.isSelected)
			.filter((col) => {
				if (col.properties.length === 2) {
					const _activeEngines = this.activeEngines.map((engines) => col.properties[engines - 1]);
					return !_activeEngines.includes(col.property);
				}
				return true;
			})
			.map((col) => {
				col.property = col.property.replace('-', '_');
				return col;
			})
			.reduce((acc, currentCol) => {
				if (currentCol.relation) {
					acc[currentCol.relation] = currentCol.property;
				} else {
					acc[currentCol.property] = currentCol.property;
				}

				return acc;
			}, {});

		this.selectedFlightPhase = await this.fetchFlightPhase();
		this.query.where.phaseId = this.selectedFlightPhase.id;

		const _query = { ...this.query, fields: [...Object.keys(cols)] };

		const params = {
			reportHeader,
			cols,
			filters: _query,
		};

		const query = `?params=${encodeURIComponent(JSON.stringify(params))}`;

		const path = `/api/ViewInputs/exportXLSX${query}`;

		const downloadLink = document.createElement('a');
		downloadLink.href = path;
		downloadLink.rel = 'noopener noreferrer';
		downloadLink.click();
		downloadLink.remove();

		// при скачивание отчета, создается запись в журнале (о просмотре отчета)
		const instance = {
			serialNumber: this.aircraft.serialNumber,
			phase: this.selectedFlightPhase.name,
		};
		this.startDate && (instance.dateStart = format(new Date(this.startDate), 'yyyy-MM-dd'));
		this.endDate && (instance.dateEnd = format(new Date(this.endDate), 'yyyy-MM-dd'));
		const newAuditItem = new store.model.Audit({
			action: 'tableReportXlsxDownload',
			model: 'Audit',
			instance,
		});
		newAuditItem.save();
	};

	onToggleEdit = (isEdit) => () => {
		this.isEdit = isEdit;
	};
}
